import { Offcanvas, Button } from "react-bootstrap";

import FeedbackButton from "../components/feedbackButton";
import StripeButton from "../components/stripeButton";
import menubutton from "../constants/icons/menu-button.png";

import fuelmelogo from "../constants/icons/fuelmelogo.png";
import servicemelogo from "../constants/icons/servicemelogo.png";

function OffcanvasMenu({
  showOffCanvas,
  setShowOffCanvas,
  handleFeedbackShow,
  title,
}) {
  const handleClose = () => setShowOffCanvas(false);
  const handleShow = () => setShowOffCanvas(true);

  return (
    <div>
      <Button className="off-canvas-button" onClick={handleShow}>
        <img
          src={menubutton}
          className="off-canvas-button-image"
          alt="menu-button"
        />
      </Button>
      <Offcanvas show={showOffCanvas} onHide={handleClose}>
        <Offcanvas.Body className="button-list">
          {title === "FuelMe" && (
            <img src={fuelmelogo} className="fuelmelogo"></img>
          )}
          {title === "ServiceMe" && (
            <img src={servicemelogo} className="servicemelogo"></img>
          )}
          <FeedbackButton handleFeedbackShow={handleFeedbackShow} />
          <StripeButton />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default OffcanvasMenu;
