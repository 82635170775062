import { useState, useEffect } from "react";
import { MdRestaurant } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import { FaDirections } from "react-icons/fa";
import { Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";

import {
  restaurantLogosMap,
  shopsLogosMap,
  fuelLogosMap,
  stationsLogoMap,
  stationsSmallLogoMap,
} from "../constants/logosMap";
// import { GetDistanceToDestination } from "../services/openRouteService.js";

function StationMarker({
  station,
  startLatLon,
  selectedStation,
  setSelectedStation,
}) {
  const map = useMap();
  const isMobile = window.innerWidth < 768;

  const [stationDistance, setStationDistance] = useState(null);

  const restaurantLogos = station.restaurants.map(
    (restaurant) =>
      restaurantLogosMap[restaurant] && (
        <img
          className="company-logo"
          src={restaurantLogosMap[restaurant]}
          alt={restaurant}
          title={restaurant}
        />
      )
  );

  const shopLogos = station.shops.map(
    (shop) =>
      shopsLogosMap[shop] && (
        <img
          className="company-logo"
          src={shopsLogosMap[shop]}
          alt={shop}
          title={shop}
        />
      )
  );

  const fuelLogos = station.fuel.map(
    (fuel) =>
      fuelLogosMap[fuel] && (
        <img
          className="company-logo"
          src={fuelLogosMap[fuel]}
          alt={fuel}
          title={fuel}
        />
      )
  );

  function getUniqueLogos(logos) {
    const foundLogos = [];
    for (let i = 0; i < logos.length; i++) {
      if (logos[i]) {
        if (foundLogos.includes(logos[i]["props"]["alt"])) {
          logos.splice(i, 1);
        } else {
          foundLogos.push(logos[i]["props"]["alt"]);
        }
      }
    }
    return logos;
  }

  var allLogos = restaurantLogos.concat(shopLogos).concat(fuelLogos);
  const uniqueLogos = getUniqueLogos(allLogos);

  function getUniqueRestaurants(restaurants) {
    const foundRestaurants = [];
    for (let i = 0; i < restaurants.length; i++) {
      if (foundRestaurants.includes(restaurants[i])) {
        restaurants.splice(i, 1);
      } else {
        foundRestaurants.push(restaurants[i]);
      }
    }
    return restaurants;
  }

  const uniqueRestaurants = getUniqueRestaurants(station.restaurants);
  const restaurantCounts = uniqueRestaurants.length;

  // calculate distance between start lonlat and station
  useEffect(() => {
    const GetDistanceToStation = async () => {
      if (startLatLon.length > 0) {
        // let distance, distanceMiles;
        if (station.name !== "Dover Port") {
          // distance = await GetDistanceToDestination(startLatLon, [station.longitude, station.latitude]);
          // distanceMiles = (0.621371*distance/1000.).toFixed(0)
          // setStationDistance(distanceMiles);
        } else {
          setStationDistance(null);
        }
      }
    };
    GetDistanceToStation();
  }, [startLatLon, station]);

  // copy address to clipboard
  const copyToClipboard = async () => {
    navigator.clipboard
      .writeText([station.latitude, station.longitude])
      .then(() => {
        // alert('Copied to clipboard!');
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const openMaps = async () => {
    const address = station.address.replaceAll(" ", "+");
    window.location.href = "https://www.google.co.uk/maps/dir//" + address;
  };

  // autocenter clicked station
  const AutoPanToMarker = (isMobile) => {
    let centerOffsetScale;
    if (isMobile) {
      centerOffsetScale = -0.2;
    } else {
      centerOffsetScale = 0.25;
    }
    const bounds = map.getBounds();
    const centerLatOffset =
      (bounds.getNorth() - bounds.getSouth()) * centerOffsetScale;

    map.flyTo(
      [station.latitude + centerLatOffset, station.longitude],
      map.getZoom()
    );
  };

  const CustomIcon = ({ restaurantCount }) => {
    let iconHTML;

    const isSpecialProvider = [
      "Extra",
      "Moto",
      "Roadchef",
      "Welcome Break",
    ].includes(station.provider);

    const iconHeight = isSpecialProvider ? "50px" : "20px";
    const iconWidth = "35px";
    const providerLogo = isSpecialProvider
      ? `<img src="${stationsSmallLogoMap[station.provider]}" alt="${
          station.provider
        }" class=station-logo-small>`
      : "";

    iconHTML = `
      <div class="station-marker-logo ${
        station === selectedStation ? "selected" : ""
      }"
      style="
        width: ${iconWidth};
        height: ${iconHeight};
      ">
        ${providerLogo}

        <div class="station-marker-info-container">
          <span class="station-marker-restaurant-count">
            ${restaurantCount}x
          </span>
          <div class="station-marker-restaurant-icon">
            ${ReactDOMServer.renderToString(<MdRestaurant />)}
          </div>
          <div class="station-marker-restaurant-nib"></div>
        </div>
      </div>`;

    // Create a custom Leaflet icon
    const customIcon = L.divIcon({
      html: iconHTML,
      className: "map-marker",
      iconAnchor: [parseInt(iconWidth) / 2, parseInt(iconHeight)],
    });

    return customIcon;
  };

  return (
    <Marker
      position={[station.latitude, station.longitude]}
      icon={CustomIcon({ restaurantCount: restaurantCounts })}
      // icon={L.divIcon({
      //   className: "map-marker",
      //   html: <div><div>ReactDOMServer.renderToString(<IoLocationSharp />)</div><span>15</span></div>,
      // })}
      eventHandlers={{
        click: () => {
          AutoPanToMarker(isMobile);
          setSelectedStation(station);
        },
      }}
    >
      {!isMobile && (
        <Popup>
          {station.provider && (
            <div>
              <img
                className="station-logo"
                src={stationsLogoMap[station.provider]}
                alt={station.provider}
              />
            </div>
          )}

          <b>{station.name} Services</b>
          {stationDistance && `, ${stationDistance} miles away.`}

          <br />
          <button className="marker-button" onClick={copyToClipboard}>
            Copy address <FaCopy />
          </button>
          <button className="marker-button" onClick={openMaps}>
            Open in maps <FaDirections />
          </button>

          <div>{uniqueLogos}</div>
          {/* <ul>
              {station.restaurants.map((restaurant, index) => (
                  <li key={index}>{restaurant}</li>
              ))}
          </ul>
          <b>shops</b>
          <ul>
              {station.shops.map((shop, index) => (
                  <li key={index}>{shop}</li>
              ))}
          </ul>
          <b>fuel</b>
          <ul>
              {station.fuel.map((fuel, index) => (
                  <li key={index}>{fuel}</li>
              ))}
          </ul> */}
        </Popup>
      )}
    </Marker>
  );
}

export default StationMarker;
