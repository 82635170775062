
export default function SearchAreaButton({ submitBboxFilters, directionsActive }) {

    return (
        <div className="search-area-button-div">
            <button 
              className={`search-area-button ${directionsActive ? "directions-active": ""}`}
              id="search-area-button"
              onClick={submitBboxFilters}
            >
                Search this area
            </button>
        </div>
    )
}
