import "./App.css";
import { useState, useEffect, useRef } from "react";

import BaseMap from "./components/map";
import SearchBox from "./components/searchBox";
import AddFiltersButton from "./components/addFiltersButton";
import AdBanner from "./components/adBanner";
import OffcanvasMenu from "./components/offCanvasMenu";
import FeedbackModal from "./components/feedbackForm";
import SearchAreaButton from "./components/searchAreaButton";
import Filters from "./components/filters.js";
import StationInfoBoxMobile from "./components/stationInfoBoxMobile.js";

import stationSample from "./constants/station_sample.json";

function App() {
  const [routeStart, setRouteStart] = useState("");
  const [routeEnd, setRouteEnd] = useState("");
  const [stations, setStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentMapBbox, setCurrentMapBbox] = useState([]); // not used
  const [currentBboxFilters, setCurrentBboxFilters] = useState([]); // not used
  const [hideSearchAreaButton, setHideSearchAreaButton] = useState(true);
  const [selectedStation, setSelectedStation] = useState();
  const [routeBbox, setRouteBbox] = useState(null);
  const [endLatLon, setEndLatLon] = useState([]);
  const [appLoaded, setAppLoaded] = useState(false);
  const [directionsActive, setDirectionsActive] = useState(false);

  const handleFeedbackShow = () => setShowFeedback(true);
  const handleFeedbackClose = () => setShowFeedback(false);

  const filterOptions = [
    // "All Locations",
    "Burger King",
    // "Chopstix Noodle Bar",
    // "Chow Asian Kitchen",
    // "Chozen Noodle",
    // "Coco di Mama",
    "Costa Coffee",
    "Greggs",
    "KFC",
    "McDonalds",
    "Nandos",
    "Pizza Express",
    "Pret A Manger",
    "Starbucks",
    "Subway",
    "Taco Bell",
    // "Tortilla",
    // "West Cornwall Pasty"
    "Extra",
    "Moto",
    "Roadchef",
    "Welcome Break",
  ];

  const defaultZoomLevel = 10;

  // Timer to track if the app has passed the initial load period, and reset state
  // variables which have been errornously updated
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!appLoaded) {
        setAppLoaded(true);
        setHideSearchAreaButton(true);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Set stations data once when the component mounts
  useEffect(() => {
    setStations(stationSample);
    // setSelectedFilters(["All Locations"]);
  }, []);

  // set current bounding box filters
  function submitBboxFilters() {
    setCurrentBboxFilters(currentMapBbox);
    setHideSearchAreaButton(true);
  }

  useEffect(() => {
    const filterStations = async () => {
      const filters = selectedFilters.filter(
        (item) => item !== "All Locations"
      );

      const keptStations = [];
      for (let i = 0; i < stations.length; i++) {
        let filtersPassed = true;
        if (filters.length > 0) {
          for (let j = 0; j < selectedFilters.length; j++) {
            if (
              !stations[i].restaurants.includes(filters[j]) &&
              !stations[i].provider.includes(filters[j])
            ) {
              filtersPassed = false;
            }
          }
        }

        let inScreenBbox = false;
        if (
          stations[i].latitude > currentMapBbox["_southWest"]["lat"] &&
          stations[i].latitude < currentMapBbox["_northEast"]["lat"] &&
          stations[i].longitude > currentMapBbox["_southWest"]["lng"] &&
          stations[i].longitude < currentMapBbox["_northEast"]["lng"]
        ) {
          inScreenBbox = true;
        }

        function inRouteBbox(station, routeBbox) {
          let nearRouteBbox = false;
          for (let i = 0; i < routeBbox.length; i++) {
            if (
              station.longitude >= Math.min(...routeBbox[i]["lon"]) &&
              station.longitude <= Math.max(...routeBbox[i]["lon"]) &&
              station.latitude >= Math.min(...routeBbox[i]["lat"]) &&
              station.latitude <= Math.max(...routeBbox[i]["lat"])
            ) {
              nearRouteBbox = true;
            }
          }
          return nearRouteBbox;
        }

        let nearRouteBbox = true;
        if (routeBbox) {
          nearRouteBbox = inRouteBbox(stations[i], routeBbox);
        }

        if (filtersPassed && inScreenBbox && nearRouteBbox) {
          keptStations.push(stations[i]);
        }
      }
      setFilteredStations(keptStations);
      document.getElementById("search-area-button").style.display = "none";
    };

    if (JSON.stringify(currentBboxFilters) !== JSON.stringify([])) {
      filterStations();
    }
  }, [selectedFilters, stations, currentBboxFilters]);

  // show search area button again when the map bounding box changes
  useEffect(() => {
    function toggleHidingSearchButton() {
      if (hideSearchAreaButton) {
        document.getElementById("search-area-button").style.display = "none";
      } else if (!hideSearchAreaButton) {
        document.getElementById("search-area-button").style.display = "block";
      }
    }

    // if (currentMapBbox.length === 0) return;
    if (appLoaded) {
      toggleHidingSearchButton();
    }
  }, [hideSearchAreaButton]);

  return (
    <div>
      <BaseMap
        position={[55, -2.0]}
        zoom={6}
        stations={filteredStations}
        routeStart={routeStart}
        routeEnd={routeEnd}
        setCurrentMapBbox={setCurrentMapBbox}
        currentBboxFilters={currentBboxFilters}
        setCurrentBboxFilters={setCurrentBboxFilters}
        hideSearchAreaButton={hideSearchAreaButton}
        setHideSearchAreaButton={setHideSearchAreaButton}
        defaultZoomLevel={defaultZoomLevel}
        selectedStation={selectedStation}
        setSelectedStation={setSelectedStation}
        setRouteBbox={setRouteBbox}
        setFilteredStations={setFilteredStations}
        endLatLon={endLatLon}
        setEndLatLon={setEndLatLon}
      />
      <SearchAreaButton
        submitBboxFilters={submitBboxFilters}
        directionsActive={directionsActive}
      />
      <SearchBox
        setRouteStart={setRouteStart}
        setRouteEnd={setRouteEnd}
        routeStart={routeStart}
        routeEnd={routeEnd}
        setEndLatLon={setEndLatLon}
        directionsActive={directionsActive}
        setDirectionsActive={setDirectionsActive}
      />
      {/* <AddFiltersButton setShowOffCanvas={setShowOffCanvas} /> */}
      <OffcanvasMenu
        showOffCanvas={showOffCanvas}
        setShowOffCanvas={setShowOffCanvas}
        handleFeedbackShow={handleFeedbackShow}
        title="ServiceMe"
      />
      <Filters
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filters={filterOptions}
        directionsActive={directionsActive}
      />
      {selectedStation && (
        <StationInfoBoxMobile
          station={selectedStation}
          setSelectedStation={setSelectedStation}
        />
      )}
      <FeedbackModal show={showFeedback} handleClose={handleFeedbackClose} />
      <AdBanner />
    </div>
  );
}

export default App;
